<template>
  <div class="merlends">
    <el-form
      ref="form"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item label="原订单号：">
        <el-input
          v-if="activeName == 'four'"
          v-model="queryParams.orderId"
          placeholder="请输入原订单号"
          clearable
        ></el-input>
        <el-input
          v-else
          v-model="queryParams.originalOrderId"
          placeholder="请输入原订单号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="原主订单号：">
        <el-input
          v-if="activeName == 'four'"
          v-model="queryParams.groupId"
          placeholder="请输入原主订单号"
          clearable
        ></el-input>
        <el-input
          v-else
          v-model="queryParams.originalGroupId"
          placeholder="请输入原主订单号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="原批次号：">
        <el-input
          v-if="activeName == 'four'"
          v-model="queryParams.orderGroupId"
          placeholder="请输入原批次号"
          clearable
        ></el-input>
        <el-input
          v-else
          v-model="queryParams.originalOrderGroupId"
          placeholder="请输入原批次号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="提现编号：">
        <el-input
          v-if="activeName == 'four'"
          v-model="queryParams.withdrawNo"
          placeholder="请输入提现编号"
          clearable
        ></el-input>
        <el-input
          v-else
          v-model="queryParams.txNo"
          placeholder="请输入提现编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.orderCreateList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="下单开始时间"
          end-placeholder="下单结束时间"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="支付时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.paymentList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="支付开始时间"
          end-placeholder="支付结束时间"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="配送商发货时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.supplierDeliveryList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="配送商发货开始时间"
          end-placeholder="配送商发货结束时间"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="发货时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.deliveryList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="发货开始时间"
          end-placeholder="发货结束时间"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结算时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.settlementList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="结算开始时间"
          end-placeholder="结算结束时间"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="提现时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.withdrawList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="提现开始时间"
          end-placeholder="提现结束时间"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="提现状态：" v-if="activeName != 'four'">
        <el-select
          v-model="queryParams.withdrawStatus"
          placeholder="请选择提现状态"
          multiple
          clearable
          style="width: 270px"
        >
          <el-option label="未提现" :value="0"></el-option>
          <el-option label="提现中" :value="1"></el-option>
          <el-option label="已提现" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="交易类型：" v-if="activeName != 'four'">
        <el-select
          v-model="queryParams.transactionType"
          placeholder="请选择交易类型"
          multiple
          clearable
          style="width: 300px"
        >
          <div v-if="activeName == 'one'">
            <el-option label="商品货款" :value="0"></el-option>
            <el-option label="平台佣金" :value="1"></el-option>
            <el-option label="业务员佣金" :value="2"></el-option>
            <el-option label="商家佣金" :value="3"></el-option>
            <el-option label="运费" :value="4"></el-option>
            <el-option label="销售退货" :value="6"></el-option>
            <el-option label="销售冲货" :value="7"></el-option>
            <el-option label="销售退款" :value="8"></el-option>
          </div>
          <div v-if="activeName == 'two'">
            <el-option label="采购进货" :value="0"></el-option>
            <el-option label="采购退货" :value="1"></el-option>
            <el-option label="采购冲货" :value="2"></el-option>
          </div>
          <div v-if="activeName == 'three'">
            <el-option label="平台手续费" :value="5"></el-option>
            <el-option label="采购商补贴手续费" :value="9"></el-option>
          </div>
        </el-select>
      </el-form-item>
      <el-form-item label="是否提现：" v-if="activeName == 'four'">
        <el-select
          v-model="queryParams.withdrawStatus"
          placeholder="请选择是否提现"
          multiple
          clearable
        >
          <el-option label="未提现" :value="0"></el-option>
          <el-option label="提现中" :value="1"></el-option>
          <el-option label="已提现" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否结算：">
        <el-select
          v-if="activeName == 'four'"
          v-model="queryParams.settlementStatus"
          placeholder="请选择结算状态"
          multiple
          clearable
        >
          <el-option label="未结算" :value="0"></el-option>
          <el-option label="已结算" :value="1"></el-option>
        </el-select>
        <el-select
          v-else
          v-model="queryParams.settleFlag"
          placeholder="请选择结算状态"
          multiple
          clearable
        >
          <el-option label="未结算" :value="0"></el-option>
          <el-option label="已结算" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型：">
        <el-select
          v-model="queryParams.deliveryService"
          placeholder="请选择订单类型"
          multiple
          clearable
        >
          <el-option label="自发货" :value="1"></el-option>
          <el-option label="合纵" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单支付方式：">
        <el-select
          v-model="queryParams.paymentMethod"
          placeholder="请选择订单支付方式"
          multiple
          collapse-tags
          clearable
        >
          <el-option
            v-for="(item, index) in PaymentData"
            :key="index"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="交易支付类型：">
        <el-select
          v-model="queryParams.paymentType"
          placeholder="请选择交易支付类型"
          multiple
          clearable
        >
          <el-option label="线下支付" :value="0"></el-option>
          <el-option label="线上支付" :value="1"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="备注：">
        <el-input
          v-model="queryParams.remark"
          placeholder="请输入备注"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="结算单号：">
        <el-input
          v-if="activeName == 'four'"
          v-model="queryParams.settlementNo"
          placeholder="请输入结算单号"
          clearable
        ></el-input>
        <el-input
          v-else
          v-model="queryParams.settlementBillNumber"
          placeholder="请输入结算单号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="分账方式：">
        <el-select
          v-if="activeName == 'four'"
          v-model="queryParams.separateType"
          placeholder="请选择分账方式"
          multiple
          clearable
        >
          <el-option label="线上分账" :value="1"></el-option>
          <el-option label="线下分账" :value="2"></el-option>
        </el-select>
        <el-select
          v-else
          v-model="queryParams.separateMode"
          placeholder="请选择分账方式"
          multiple
          clearable
        >
          <el-option label="线上分账" :value="0"></el-option>
          <el-option label="线下分账" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否分账：">
        <el-select
          v-if="activeName == 'four'"
          v-model="queryParams.separateStatus"
          placeholder="请选择是否分账"
          multiple
          clearable
        >
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
        <el-select
          v-else
          v-model="queryParams.whetherSeparateAccounts"
          placeholder="请选择是否分账"
          multiple
          clearable
        >
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商：">
        <el-input
          v-model="queryParams.supplier"
          placeholder="请输入供应商"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="handleQuery"
          >查 询</el-button
        >
        <el-button size="small" type="primary" @click="handleReset"
          >重 置</el-button
        >
      </el-form-item>
    </el-form>
    <!-- tab切换 -->
    <div class="toponsd">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="结算分账" name="four" />
        <el-tab-pane label="销售订单" name="one" />
        <el-tab-pane label="采购订单" name="two" />
        <el-tab-pane label="手续费账单" name="three" />
      </el-tabs>
      <div class="abobtn">
        <el-button size="small" type="primary" @click="downLoad"
          >导 出</el-button
        >
      </div>
    </div>
    <!-- 列表 -->
    <div>
      <!-- 结算分账 -->
      <Settlementledger
        v-show="activeName == 'four'"
        :loading="loading"
        :tableData="SettList"
        :queryParams="queryParams"
      />
      <!-- 销售订单 -->
      <SalesOrders
        v-show="activeName == 'one'"
        :loading="loading"
        :tableData="SalesList"
        :queryParams="queryParams"
      />
      <!-- 采购订单 -->
      <PurchaseOrder
        v-show="activeName == 'two'"
        :loading="loading"
        :tableData="PurchList"
        :queryParams="queryParams"
      />
      <!-- 手续费账单 -->
      <ServicefeeBill
        v-show="activeName == 'three'"
        :loading="loading"
        :tableData="ServiceList"
        :queryParams="queryParams"
      />
    </div>
    <!-- 统计 -->
    <div class="ardbottm">
      <div>
        <div v-show="activeName == 'one'">
          <span>销售下单合计：</span>
          <span>{{
            Number(moneyfrom.salesAmout) + Number(moneyfrom.shippingFee) < 0
              ? "-" +
                "￥" +
                Math.abs(
                  Number(moneyfrom.salesAmout) + Number(moneyfrom.shippingFee)
                ).toFixed(2)
              : "+" +
                "￥" +
                Math.abs(
                  Number(moneyfrom.salesAmout) + Number(moneyfrom.shippingFee)
                ).toFixed(2)
          }}</span>
        </div>
        <div v-show="activeName == 'one'">
          <span>货款合计：</span>
          <span>{{
            Number(moneyfrom.salesAmout) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.salesAmout))
              : "+" + "￥" + Math.abs(Number(moneyfrom.salesAmout))
          }}</span>
        </div>
        <div v-show="activeName == 'one'">
          <span>运费：</span>
          <span>
            {{
              Number(moneyfrom.shippingFee) < 0
                ? "-" + "￥" + Math.abs(Number(moneyfrom.shippingFee))
                : "+" + "￥" + Math.abs(Number(moneyfrom.shippingFee))
            }}
          </span>
        </div>
        <div v-show="activeName == 'two'">
          <span>采购进货合计：</span>
          <span>{{
            Number(moneyfrom.purchaseAmout) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.purchaseAmout))
              : "+" + "￥" + Math.abs(Number(moneyfrom.purchaseAmout))
          }}</span>
        </div>
      </div>
      <div>
        <span v-if="activeName == 'one'">销售冲货金额：</span>
        <span v-if="activeName == 'two'">采购冲货合计：</span>
        <span v-if="activeName == 'three'">平台手续费金额：</span>
        <span v-if="['one', 'two'].includes(activeName)">
          {{
            Number(moneyfrom.refundAmount) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.refundAmount))
              : "+" + "￥" + Math.abs(Number(moneyfrom.refundAmount))
          }}
        </span>
        <span v-if="['three'].includes(activeName)">
          {{
            Number(moneyfrom.platformFee) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.platformFee))
              : "+" + "￥" + Math.abs(Number(moneyfrom.platformFee))
          }}
        </span>
      </div>
      <div>
        <span v-if="activeName == 'one'">销售退货金额：</span>
        <span v-if="activeName == 'two'">采购退货合计：</span>
        <span v-if="activeName == 'three'">采购商补贴手续费合计：</span>
        <span v-if="['one', 'two'].includes(activeName)">
          {{
            Number(moneyfrom.returnAmount) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.returnAmount))
              : "+" + "￥" + Math.abs(Number(moneyfrom.returnAmount))
          }}
        </span>
        <span v-if="['three'].includes(activeName)">
          {{
            Number(moneyfrom.purchaseSubsidyFee) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.purchaseSubsidyFee))
              : "+" + "￥" + Math.abs(Number(moneyfrom.purchaseSubsidyFee))
          }}
        </span>
      </div>
      <div v-show="activeName == 'one'">
        <div>
          <span>销售退款金额：</span>
          <span>
            {{
              Number(moneyfrom.reversalAmount) < 0
                ? "-" + "￥" + Math.abs(Number(moneyfrom.reversalAmount))
                : "+" + "￥" + Math.abs(Number(moneyfrom.reversalAmount))
            }}
          </span>
        </div>
        <div>
          <span>平台佣金：</span>
          <span>
            {{
              Number(moneyfrom.platformCommission) < 0
                ? "-" + "￥" + Math.abs(Number(moneyfrom.platformCommission))
                : "+" + "￥" + Math.abs(Number(moneyfrom.platformCommission))
            }}
          </span>
        </div>
        <div>
          <span>业务员佣金：</span>
          <span>
            {{
              Number(moneyfrom.salesmanCommission) < 0
                ? "-" + "￥" + Math.abs(Number(moneyfrom.salesmanCommission))
                : "+" + "￥" + Math.abs(Number(moneyfrom.salesmanCommission))
            }}
          </span>
        </div>
        <div>
          <span>商家佣金：</span>
          <span>
            {{
              Number(moneyfrom.merchantCommission) < 0
                ? "-" + "￥" + Math.abs(Number(moneyfrom.merchantCommission))
                : "+" + "￥" + Math.abs(Number(moneyfrom.merchantCommission))
            }}
          </span>
        </div>
      </div>
      <div class="red" v-show="['one', 'two', 'three'].includes(activeName)">
        收支合计：
        <span
          >{{
            Number(moneyfrom.calcAll) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.calcAll))
              : "+" + "￥" + Math.abs(Number(moneyfrom.calcAll))
          }}
        </span>
      </div>
      <div class="red" v-show="['one', 'two'].includes(activeName)">
        未结算订单：
        <span>{{ moneyfrom.notSettleNum }}笔</span>
      </div>
      <div v-show="['four'].includes(activeName)">
        <div>
          订单笔数：
          <span>{{ moneyfrom.orderCount }}笔</span>
        </div>
        <div class="red">
          结算金额：
          <span>￥{{ moneyfrom.totalSettlementAmount }}</span>
        </div>
        <div class="red">
          供应商分账金额：
          <span>￥{{ moneyfrom.totalSupplierSeparateAmount }}</span>
        </div>
        <div class="red">
          采购商分账金额：
          <span>￥{{ moneyfrom.totalPurchaserSeparateAmount }}</span>
        </div>
        <div class="red">
          平台分账金额：
          <span>￥{{ moneyfrom.totalPlatformSeparateAmount }}</span>
        </div>
      </div>
    </div>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
    <!-- 电子回单弹窗 -->
    <el-dialog
      title="电子回单"
      :visible.sync="dialogVisible"
      width="400px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div>
        <el-image
          style="height: 150px"
          :src="electItem.imgts"
          :preview-src-list="[electItem.imgts]"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click="Downloasert">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  obtainOrderReceipt, //获取新网电子回单
} from "@/api/apiAll/javaUrl";
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const Order = createNamespacedHelpers("Order"); //vuex公共库
const billList = createNamespacedHelpers("billList"); //vuex公共库
import PurchaseOrder from "./PurchaseOrder.vue";
import SalesOrders from "./SalesOrders.vue";
import ServicefeeBill from "./ServicefeeBill.vue";
import Settlementledger from "./Settlementledger.vue";
export default {
  name: "accountStatement",
  components: {
    SalesOrders, //销售订单
    PurchaseOrder, //采购订单
    ServicefeeBill, //手续费账单
    Settlementledger, //结算分账
  },
  watch: {
    //下单时间
    "queryParams.orderCreateList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.orderCreateStartTime = newVal[0];
          this.queryParams.orderCreateEndTime = newVal[1];
        } else {
          this.queryParams.orderCreateStartTime = "";
          this.queryParams.orderCreateEndTime = "";
        }
      },
      deep: true,
    },
    //支付时间
    "queryParams.paymentList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.paymentStartTime = newVal[0];
          this.queryParams.paymentEndTime = newVal[1];
        } else {
          this.queryParams.paymentStartTime = "";
          this.queryParams.paymentEndTime = "";
        }
      },
      deep: true,
    },
    //配送商发货时间
    "queryParams.supplierDeliveryList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.supplierDeliveryStartTime = newVal[0];
          this.queryParams.supplierDeliveryEndTime = newVal[1];
        } else {
          this.queryParams.supplierDeliveryStartTime = "";
          this.queryParams.supplierDeliveryEndTime = "";
        }
      },
      deep: true,
    },
    //发货时间
    "queryParams.deliveryList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.deliveryStartTime = newVal[0];
          this.queryParams.deliveryEndTime = newVal[1];
        } else {
          this.queryParams.deliveryStartTime = "";
          this.queryParams.deliveryEndTime = "";
        }
      },
      deep: true,
    },
    //结算时间
    "queryParams.settlementList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.settlementStartTime = newVal[0];
          this.queryParams.settlementEndTime = newVal[1];
        } else {
          this.queryParams.settlementStartTime = "";
          this.queryParams.settlementEndTime = "";
        }
      },
      deep: true,
    },
    //提现时间
    "queryParams.withdrawList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.withdrawStartTime = newVal[0];
          this.queryParams.withdrawEndTime = newVal[1];
        } else {
          this.queryParams.withdrawStartTime = "";
          this.queryParams.withdrawEndTime = "";
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      queryParams: {
        page: 1, // 页码
        perPage: 10, //每页大小
        originalOrderId: "", //原订单号
        originalGroupId: "", //原主订单号
        originalOrderGroupId: "", //原批次号
        txNo: "", //提现编号
        settlementBillNumber: "", //结算账单号

        orderCreateStartTime: "", //下单开始时间
        orderCreateEndTime: "", //下单结束时间
        paymentStartTime: "", //支付开始时间
        paymentEndTime: "", //支付结束时间
        supplierDeliveryStartTime: "", //配送商发货开始时间
        supplierDeliveryEndTime: "", //配送商发货结束时间
        deliveryStartTime: "", //发货开始时间
        deliveryEndTime: "", //	发货结束时间
        settlementStartTime: "", //结算开始时间
        settlementEndTime: "", //结算结束时间
        withdrawStartTime: "", //提现开始时间
        withdrawEndTime: "", //提现结束时间

        orderCreateList: [], //下单时间
        paymentList: [], //支付时间
        supplierDeliveryList: [], //配送商发货时间
        deliveryList: [], //发货时间
        settlementList: [], //结算时间
        withdrawList: [], //提现时间

        withdrawStatus: [], //提现状态、是否提现
        transactionType: [], //交易类型
        settleFlag: [], //是否结算
        deliveryService: [], //订单类型
        paymentMethod: [], //订单支付方式
        paymentType: [], //交易支付类型
        remark: "", //备注
        supplier: "", //供应商
        settlementBillNumber: "", //结算单号
        separateMode: "", //分账方式
        whetherSeparateAccounts: "", //是否分账

        orderId: "", //原订单号
        groupId: "", //原主订单号
        orderGroupId: "", //原批次号
        withdrawNo: "", //提现编号
        settlementStatus: "", //是否结算
        settlementNo: "", //结算单号
        separateType: "", //分账方式
        separateStatus: "", //是否分账
      },

      PaymentData: [], // 支付方式
      activeName: "four", //tab状态
      total: 0,
      loading: false,
      SettList: [], //结算分账列表
      SalesList: [], //销售订单列表
      PurchList: [], //采购订单列表
      ServiceList: [], //手续费账单列表
      moneyfrom: {
        purchaseAmout: 0, //采购进货合计
        salesAmout: 0, //销售下单合计
        platformCommission: "", //平台佣金
        salesmanCommission: "", //业务员佣金
        merchantCommission: "", //商家佣金
        shippingFee: "", //运费

        refundAmount: 0, //采购冲货合计、销售冲货金额
        returnAmount: 0, //采购退货合计、销售退货金额
        reversalAmoun: 0, //

        platformFee: "", //平台手续费
        purchaseSubsidyFee: "", //采购商补贴手续费

        calcAll: 0, //收支合计
        notSettleNum: 0, //未结算订单

        orderCount: "", //订单笔数
        totalSettlementAmount: "", //总结算金额
        totalSupplierSeparateAmount: "", //供应商分账总金额
        totalPurchaserSeparateAmount: "", //采购商分账总金额
        totalPlatformSeparateAmount: "", //平台分账总金额
      },
      lastRequestId: 0, // 记录最后一次请求的ID
      lastResponse: null, // 记录最后一次请求的响应数据

      dialogVisible: false, //电子回单弹窗
      electItem: {
        id: "",
        imgts: "",
      },
    };
  },

  created() {
    console.log("11111111111111");

    this.getPaymentList();
    let params = this.$route.params;
    if (params.data) {
      this.queryParams = Object.assign(this.queryParams, params.data);
      this.activeName = "four";
    } else {
      //获取存储查询
      let path = this.$route.path;
      if (this.$store.getters.routData[path]) {
        Object.assign(
          this.queryParams,
          this.$store.getters.routData[path].queryParams
        );
        this.activeName = this.$store.getters.routData[path].activeName;
      }
    }
    this.getItem();
  },
  methods: {
    ...Order.mapActions([
      "getPaymentTypeList", //获取支付方式
    ]),
    ...billList.mapActions([
      "salesStatement", //销售对账单列表
      "purchaseStatement", //采购对账单列表
      "commissionStatement", //手续费对账单列表
      "salesSeparate", //结算分账列表
    ]),
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    },
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
        activeName: this.activeName,
      };
      this.$store.dispatch("routing/querying", data);
    },
    //查询按钮操作
    handleQuery() {
      this.queryParams.page = 1;
      this.getItem();
    },
    /** 查询列表数据 */
    getList() {
      this.loading = true;
      if (this.activeName == "four") {
        this.salesSeparate(this.queryParams).then((res) => {
          this.SettList = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      }
      if (this.activeName == "one") {
        this.salesStatement(this.queryParams).then((res) => {
          this.SalesList = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      }
      if (this.activeName == "two") {
        this.purchaseStatement(this.queryParams).then((res) => {
          this.PurchList = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      }
      if (this.activeName == "three") {
        this.commissionStatement(this.queryParams).then((res) => {
          this.ServiceList = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
        this.loading = false;
      }
      this.setquery();
    },
    //** 获取页面所有数据 */
    async getItem() {
      await this.getList();
      const requestId = Date.now(); // 生成一个唯一请求ID
      this.lastRequestId = requestId; // 记录最后一次请求ID
      //获取订单统计
      var from = { ...this.queryParams };
      from.opt = "statistics";
      try {
        if (this.activeName == "one") {
          var response = await this.salesStatement(from); // 发起接口请求
        }
        if (this.activeName == "two") {
          var response = await this.purchaseStatement(from); // 发起接口请求
        }
        if (this.activeName == "three") {
          var response = await this.commissionStatement(from); // 发起接口请求
        }
        if (this.activeName == "four") {
          var response = await this.salesSeparate(from); // 发起接口请求
        }
        // 只保存最后一次请求的数据
        if (requestId === this.lastRequestId) {
          this.lastResponse = response;
          // 更新页面数据或进行其他操作
          this.updatePageData(this.lastResponse);
        }
      } catch (error) {
        // 处理请求错误
        // console.error("请求错误:", error);
      }
    },
    updatePageData(response) {
      if (response.data) {
        Object.assign(this.moneyfrom, response.data);
      }
    },
    // 重置
    handleReset() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getItem();
    },
    //切换tab状态
    async handleClick() {
      // Object.assign(this.queryParams, this.$options.data().queryParams);
      this.queryParams.transactionType = [];
      // this.queryParams.supplier = "";
      this.queryParams.page = 1;
      this.queryParams.perPage = 10;
      if (this.activeName == "four") {
        this.queryParams.orderId = this.queryParams.originalOrderId;
        this.queryParams.groupId = this.queryParams.originalGroupId;
        this.queryParams.orderGroupId = this.queryParams.originalOrderGroupId;
        this.queryParams.withdrawNo = this.queryParams.txNo;
        this.queryParams.settlementStatus = this.queryParams.settleFlag;
        this.queryParams.settlementNo = this.queryParams.settlementBillNumber;
        var array = [];
        this.queryParams.separateMode.forEach((v) => {
          if (v == 0) {
            array.push(1);
          }
          if (v == 1) {
            array.push(2);
          }
        });
        this.queryParams.separateType = array;
        this.queryParams.separateStatus =
          this.queryParams.whetherSeparateAccounts;
      } else {
        this.queryParams.originalOrderId =
          this.queryParams.orderId || this.queryParams.originalOrderId;
        this.queryParams.originalGroupId =
          this.queryParams.groupId || this.queryParams.originalGroupId;
        this.queryParams.originalOrderGroupId =
          this.queryParams.orderGroupId ||
          this.queryParams.originalOrderGroupId;
        this.queryParams.txNo =
          this.queryParams.withdrawNo || this.queryParams.txNo;
        this.queryParams.settleFlag =
          this.queryParams.settlementStatus || this.queryParams.settleFlag;
        this.queryParams.settlementBillNumber =
          this.queryParams.settlementNo ||
          this.queryParams.settlementBillNumber;
        var array = [];
        this.queryParams.separateType.forEach((v) => {
          if (v == 1) {
            array.push(0);
          }
          if (v == 2) {
            array.push(1);
          }
        });
        this.queryParams.separateMode = array || this.queryParams.separateMode;
        this.queryParams.whetherSeparateAccounts =
          this.queryParams.separateStatus ||
          this.queryParams.whetherSeparateAccounts;
      }
      await this.getItem();
    },
    //** 获取支付方式 */
    async getPaymentList() {
      let res = await this.getPaymentTypeList();
      if (res.code == 200) {
        this.PaymentData = res.data;
      }
    },
    //** 导出 */
    downLoad() {
      if (this.activeName == "one") {
        downLoadZip(
          `/platformStatements/salesStatementExport`,
          "xlsx",
          "销售对账单",
          this.queryParams,
          "php",
          "post"
        );
      }
      if (this.activeName == "two") {
        downLoadZip(
          `/platformStatements/purchaseStatementExport`,
          "xlsx",
          "采购对账单",
          this.queryParams,
          "php",
          "post"
        );
      }
      if (this.activeName == "three") {
        downLoadZip(
          `/platformStatements/commissionStatementExport`,
          "xlsx",
          "手续费账单",
          this.queryParams,
          "php",
          "post"
        );
      }
      if (this.activeName == "four") {
        downLoadZip(
          `/platformStatements/salesSeparateExport`,
          "xlsx",
          "结算分账",
          this.queryParams,
          "php",
          "post"
        );
      }
    },
    /** 打开电子回单弹窗 */
    upelectsd(serialNumber) {
      this.electItem.serialNumber = serialNumber;
      obtainOrderReceipt({ serialNumber }).then((res) => {
        if (res.code == "000000") {
          this.electItem.imgts = res.content;
          // this.dialogVisible = true;
          this.downloadPdf(res.content);
        }
      });
    },
    /** 下载电子回单 */
    Downloasert() {
      this.downloadImage(this.electItem.imgts, "电子回单");
    },
    //** 下载pdf */
    downloadPdf(url) {
      // // 创建隐藏的可下载链接
      // var element = document.createElement("a");
      // element.setAttribute("href", url);
      // element.setAttribute("download", ""); // 可以指定下载后的文件名
      // document.body.appendChild(element);
      // // 触发点击
      // element.click();
      // // 然后移除这个元素
      // document.body.removeChild(element);
      window.open(url);
    },

    /**
     * imageUrl: 要下载的图片地址
     * name: 下载后的图片名称
     */
    downloadImage(imageUrl, name) {
      const image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        // 得到图片的base64编码数据
        const url = canvas.toDataURL("image/png");
        // 生成一个a元素
        const a = document.createElement("a");
        // 创建一个单击事件
        const event = new MouseEvent("click");
        a.download = name;
        // 将生成的URL设置为a.href属性
        a.href = url;
        // 触发a的单击事件
        a.dispatchEvent(event);
      };
      image.src = imageUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 5px;
}
.toponsd {
  margin-left: 10px;
  position: relative;
  .abobtn {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
.ardbottm {
  margin-top: 10px;
  background: rgb(242, 243, 245);
  padding: 10px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  line-height: 20px;
}
.ardbottm > div {
  display: flex;
  flex-wrap: wrap;
}
.ardbottm > div > div {
  margin-right: 20px;
}
.ardbottm > div > span:last-child {
  margin-right: 20px;
}
</style>
